// src/pages/image-generator/ImageGeneratorPage.jsx

import { useEffect, useState, useCallback } from "react";
import MainHeaderTitle from "../../../components/main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faImage,
    faObjectGroup,
    faWandMagicSparkles,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./ImageGeneratorPage.css";
import Submenu from "../../../components/submenu/Submenu";
import ImageGallery from "../components/gallery/ImageGallery";
import CommunityGallery from "../community-gallery/CommunityGallery";
import ImageGenerator from "../components/generator/ImageGenerator";
import ImageEditor from "../components/editor/ImageEditor";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ImageCreditsWidget from "../components/widget/ImageCreditsWidget";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import api from '../../../services/api'; // Importar a API
import Onboarding from '../../../components/onboarding/Onboarding'; // Importar o componente de Onboarding
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from "@tanstack/react-query";

const ImageGeneratorPage = ({ setHeaderTitle }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const [screen, setScreen] = useState("generator");
    const isMobile = useIsMobile();
    const isSmallScreen = useMediaQuery({ maxWidth: 1599 });

    const [user, setUser] = useState(null);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Move a função fetchUser para fora do useEffect
    const fetchUser = useCallback(async () => {
        try {
            setIsLoading(true);
            console.log('Buscando dados do usuário...');
            
            const response = await api.get('/user'); // Removido o prefixo /api
            
            if (typeof response.data === 'string') {
                console.error('Resposta inválida:', response.data);
                return;
            }

            console.log('Dados do usuário:', response.data);
            const userData = response.data;
            setUser(userData);

            const hasSeenImagesOnboarding = userData.hasSeenImagesOnboarding;
            console.log('Status do onboarding de imagens:', hasSeenImagesOnboarding);

            if (hasSeenImagesOnboarding === null || hasSeenImagesOnboarding === false) {
                console.log('Onboarding não foi visto, mostrando...');
                setShowOnboarding(true);
            } else {
                console.log('Onboarding já foi visto');
                setShowOnboarding(false);
            }
        } catch (error) {
            console.error('Erro ao buscar dados do usuário:', error);
            if (error.response) {
                console.error('Detalhes do erro:', {
                    status: error.response.status,
                    data: error.response.data,
                    headers: error.response.headers,
                    url: error.config.url
                });
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        document.title = "Imagens · CMOs.ai";
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faImage} />}
                title="Imagens"
            />
        );

        if (location.pathname === '/image' || location.pathname === '/image/') {
            navigate(`/image/${screen}`);
        }

        fetchUser();
    }, [location.pathname, navigate, screen, setHeaderTitle, fetchUser]);

        // Effect para monitorar mudanças no status do onboarding
        useEffect(() => {
            if (!showOnboarding) {
                console.log('Verificando status após fechamento do onboarding...');
                fetchUser();
            }
        }, [showOnboarding, fetchUser]);

        const handleOnboardingFinish = async () => {
            try {
                console.log('Iniciando atualização do onboarding de imagens...');
                
                const response = await api.post('/user/onboarding/images', { // Removido o prefixo /api
                    has_seen_images_onboarding: true
                }, {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache'
                    }
                });
    
                console.log('Resposta da atualização:', response.data);

                if (response.status === 200) {
                    // Atualiza o cache do React Query
                    await queryClient.invalidateQueries(['user']);
                    
                    // Verifica se a atualização foi bem-sucedida
                    const verifyResponse = await api.get('/user');
                    console.log('Verificação após atualização:', verifyResponse.data);
                    
                    if (verifyResponse.data.hasSeenImagesOnboarding === true) {
                        setShowOnboarding(false);
                        setUser(verifyResponse.data);
                    } else {
                        console.error('Atualização não foi persistida');
                        throw new Error('Falha na persistência do status');
                    }
                } else {
                    throw new Error('Resposta inválida do servidor');
                }
            } catch (error) {
                console.error('Erro ao atualizar onboarding:', error);
                if (error.response) {
                    console.error('Detalhes do erro:', {
                        status: error.response.status,
                        data: error.response.data,
                        headers: error.response.headers,
                        url: error.config.url
                    });
                }
                // Mantém o onboarding visível em caso de erro
                setShowOnboarding(true);
            }
        };

    const handleScreenChange = (screen, idImage) => {
        setScreen(screen);

        if (idImage) {
            navigate(`/image/${screen}`, { state: { idImage } }); 
        } else {
            navigate(`/image/${screen}`); 
        }
    }

    const renderImageCreditsWidget = () => {
        return (
            <div>
                <ImageCreditsWidget />
            </div>
        );
    }

    const renderMenu = () => {
        const items = [
          {
            key: "generator",
            label: (
              <div className="submenu-item">
                <FontAwesomeIcon icon={faWandMagicSparkles} className="submenu-icon" />
                <span>Gerador</span>
              </div>
            )
          },
          {
            key: "gallery",
            label: (
              <div className="submenu-item">
                <FontAwesomeIcon icon={faObjectGroup} className="submenu-icon" />
                <span>Galeria</span>
              </div>
            )
          },
          {
            key: "community",
            label: (
              <div className="submenu-item community-button"> {/* Adicionado a classe 'community-button' */}
                <FontAwesomeIcon icon={faUsers} className="submenu-icon" />
                <span>Comunidade</span>
              </div>
            )
          },
        ];

        const itemsMobile = [
            {
                key: "generator",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faWandMagicSparkles} className="submenu-icon" />
                        <span>Gerador</span>
                    </div>
                )
            },
            {
                key: "gallery",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faObjectGroup} className="submenu-icon" />
                        <span>Galeria</span>
                    </div>
                )
            },
            {
                key: "community",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faUsers} className="submenu-icon" />
                        <span>Comunidade</span>
                    </div>
                )
            },
        ];

        return (
            <div className="submenu-container">
                <Submenu
                    items={items}
                    selectedKeys={[screen]}
                    onClick={(item) => handleScreenChange(item.key)}
                    footer={!isSmallScreen ? renderImageCreditsWidget() : null}
                />
                {isSmallScreen && (
                    <div className="image-credits-widget-container">
                        {renderImageCreditsWidget()}
                    </div>
                )}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className="ImageGeneratorPage">
                <Routes>
                    <Route path="generator" element={<ImageGenerator onScreenChange={handleScreenChange} />} />
                    <Route path="gallery" element={<ImageGallery onScreenChange={handleScreenChange} />} />
                    <Route path="editor" element={<ImageEditor onScreenChange={handleScreenChange} />} />
                    <Route path="community" element={<CommunityGallery onScreenChange={handleScreenChange} />} />
                </Routes>
            </div>
        );
    }

    return (
        <>
            {renderMenu()}
            {renderContent()}
            {showOnboarding && user && (
                <Onboarding
                    user={user}
                    onFinish={handleOnboardingFinish}
                    onboardingType="images"
                />
            )}
        </>
    );
}

export default ImageGeneratorPage;
