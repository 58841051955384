// src/components/onboarding/Onboarding.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import Step0 from './Step0'; // Import Step0
import Step1 from './Step1'; // Import Step1
import Step2 from './Step2'; // Import Step2
import Step3 from './Step3'; // Import Step3
import Step4 from './Step4'; // Import Step4
import Step5 from './Step5'; // Import Step5

import AgentsStep1 from './agents/Step1'; // Import AgentsStep1
import AgentsStep2 from './agents/Step2'; // Import AgentsStep2
import AgentsStep3 from './agents/Step3'; // Import AgentsStep3

import ImagesStep1 from './imagens/Step1'; // Import ImagesStep1
import ImagesStep2 from './imagens/Step2'; // Import ImagesStep2
import ImagesStep3 from './imagens/Step3'; // Importamos o ImagesStep3

import api from '../../services/api';
import useIsMobile from '../../utils/hooks/useIsMobile'; // Importa o hook useIsMobile

const Onboarding = ({ user, onFinish, onboardingType }) => {
  console.log('Componente Onboarding renderizado');

  const queryClient = useQueryClient(); // Adicione esta linha
  const isMobile = useIsMobile();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleFinish = async () => {
    try {
      let response;
      if (onboardingType === 'agents') {
        response = await api.post('/api/user/onboarding/agents', { has_seen_agents_onboarding: true });
      } else if (onboardingType === 'images') {
        response = await api.post('/api/user/onboarding/images', { has_seen_images_onboarding: true });
      } else {
        response = await api.post('/api/user/onboarding', { has_seen_onboarding: true });
      }
      
      console.log('Resposta da API onboarding:', response.data);
      
      // Força uma atualização dos dados do usuário no cliente
      await queryClient.invalidateQueries(['user']);
      
      onFinish();
    } catch (error) {
      console.error('Erro ao atualizar status do onboarding:', error);
      console.error('Detalhes do erro:', error.response?.data);
    }
  };

  if (isMobile) {
    return null; // Retorna após chamar os hooks
  }

  // Define os passos do onboarding com base no tipo
  let steps = [];

  if (onboardingType === 'agents') {
    steps = [
      {
        component: AgentsStep1,
        props: {
          onNext: handleNext,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
      {
        component: AgentsStep2,
        props: {
          onNext: handleNext,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
      {
        component: AgentsStep3,
        props: {
          onNext: handleFinish,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
    ];
  } else if (onboardingType === 'images') {
    steps = [
      {
        component: ImagesStep1,
        props: {
          onNext: handleNext,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
      {
        component: ImagesStep2,
        props: {
          onNext: handleNext,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
      {
        component: ImagesStep3,
        props: {
          onNext: handleFinish,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
    ];
  } else {
    // Onboarding padrão para o chat
    steps = [
      {
        component: Step0,
        props: {
          onNext: handleNext,
          onSkip: handleFinish, // Adicionado onSkip
        },
      },
      {
        component: Step1,
        props: {
          onNext: handleNext,
          onSkip: handleFinish,
        },
      },
      {
        component: Step3,
        props: {
          onNext: handleNext,
          onSkip: handleFinish,
        },
      },
      {
        component: Step2,
        props: {
          onNext: handleNext,
          onSkip: handleFinish,
        },
      },
      {
        component: Step4,
        props: {
          onNext: handleNext,
          onSkip: handleFinish,
        },
      },
      {
        component: Step5,
        props: {
          onNext: handleFinish,
          onSkip: handleFinish,
        },
      },
    ];
  }

  if (currentStep >= steps.length) {
    return null;
  }

  const CurrentStepComponent = steps[currentStep].component;
  const stepProps = steps[currentStep].props;

  return <CurrentStepComponent {...stepProps} />;
};

Onboarding.propTypes = {
  user: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  onboardingType: PropTypes.string.isRequired,
};

export default Onboarding;
