import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step4.css';

const Step4 = ({ onNext, onSkip }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const targetElement = document.querySelector('.specialty-selector');
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        const balloonWidth = 295; // Aumentado para corresponder ao CSS
        const balloonHeight = 125; // Aumentado para posicionar mais acima
        const offset = 40; // Aumentado o offset para maior distância

        setPosition({
          top: rect.top + window.scrollY - balloonHeight - offset,
          left: rect.left + window.scrollX - (balloonWidth / 2) + (rect.width / 2),
        });
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleNext = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onNext();
    }, 300);
  }, [onNext]);

  const handleSkip = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onSkip();
    }, 300);
  }, [onSkip]);

  return (
    <div
      className={`onboarding-step4 ${isClosing ? 'closing' : ''}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      <div className="balloon">
        <button onClick={handleSkip} className="close-button">×</button>
        <span className="step-number">4</span>
        <p>Escolha aqui qual deve ser a abordagem e o escopo das respostas que o modelo deve considerar ao elaborar a resposta.</p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Próximo</button>
        </div>
      </div>
    </div>
  );
};

Step4.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default Step4;