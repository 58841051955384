// src/components/onboarding/imagens/Step1.jsx

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Step1.css';

const ImagesStep1 = ({ onNext, onSkip }) => {
  const [position, setPosition] = useState(null);
  const [arrowDirection, setArrowDirection] = useState('left'); // 'left' ou 'right'
  const [isVisible, setIsVisible] = useState(false); // Controle de visibilidade

  useEffect(() => {
    const adjustPosition = () => {
      // Seleciona o elemento do widget de créditos de imagem
      const creditsWidget = document.querySelector('.ImageCreditsWidgetContainer');
      if (creditsWidget) {
        const rect = creditsWidget.getBoundingClientRect();
        const balloon = document.querySelector('.images-onboarding-step1 .images-step1-balloon');
        const balloonWidth = balloon ? balloon.offsetWidth : 280; // Usando max-width definido no CSS
        const balloonHeight = balloon ? balloon.offsetHeight : 160; // Estimativa da altura do balão

        const viewportWidth = window.innerWidth;

        // Determina se o widget está no lado esquerdo ou direito da tela
        const widgetCenterX = rect.left + rect.width / 2;
        const isWidgetOnLeft = widgetCenterX < viewportWidth / 2;

        let top = rect.top + window.scrollY + rect.height / 2 - balloonHeight / 2;
        let left;

        if (isWidgetOnLeft) {
          // Widget está na esquerda, posiciona o balão à direita
          left = rect.right + window.scrollX + 20; // Ajuste horizontal para posicionar à direita
          setArrowDirection('left');
          // Verifica se o balão não ultrapassa a largura da tela
          if (left + balloonWidth > viewportWidth - 20) {
            // Ajusta para não sair da tela
            left = viewportWidth - balloonWidth - 20;
          }
        } else {
          // Widget está na direita, posiciona o balão à esquerda
          left = rect.left + window.scrollX - balloonWidth - 20; // Ajuste horizontal para posicionar à esquerda
          setArrowDirection('right');
          // Verifica se o balão não sai da tela
          if (left < 20) {
            left = 20;
          }
        }

        setPosition({ top, left });
      } else {
        // Se o elemento não foi encontrado, tenta novamente após um pequeno atraso
        setTimeout(adjustPosition, 100);
      }
    };

    // Chama a função imediatamente
    adjustPosition();

    // Atualiza a posição em caso de redimensionamento da janela
    window.addEventListener('resize', adjustPosition);

    // Adiciona a classe 'show' para iniciar a animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Pequeno atraso para garantir a transição

    // Limpeza
    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  // Função para lidar com o clique no botão "Entendi"
  const handleNext = () => {
    setIsVisible(false); // Inicia a animação de saída
    setTimeout(() => {
      onNext(); // Chama a função onNext após a animação
    }, 500); // Tempo deve corresponder à duração da transição (0.5s)
  };

  // Se a posição ainda não foi calculada, não renderiza o balão
  if (!position) {
    return null;
  }

  return (
    <div
      className={`images-onboarding-step1 ${isVisible ? 'show' : ''}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
    <div className={`images-step1-balloon arrow-${arrowDirection}`}>
        <button onClick={onSkip} className="close-button">×</button>
        <span className="step-number">1</span>
        <p>
            Você recebeu créditos para usar com as suas tarefas no CMOs.ai. Acompanhe aqui o seu consumo.
        </p>
        <div className="actions">
            <button onClick={handleNext} className="next-button">Entendi</button>
        </div>
    </div>
    </div>
  );
};

ImagesStep1.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default ImagesStep1;
