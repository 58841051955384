// Step0.jsx
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './Step0.css';

const Step0 = ({ onNext, onSkip }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const getVideoUrl = useCallback(() => {
    const prefix = process.env.REACT_APP_TOOL_CATEGORIES_ICON_S3_BUCKET_PREFIX_URL;
    return `${prefix}/MVPCMOsaii.mp4`;
  }, []);

  // Função para reiniciar o vídeo
  const resetVideo = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.currentTime = 0;
      videoElement.play().catch(console.error);
    }
  }, []);

  // Função para parar o vídeo
  const stopVideo = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
      // Remover a fonte do vídeo para garantir que o áudio pare imediatamente
      videoElement.src = '';
      videoElement.load();
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
  
    const initVideo = async () => {
      try {
        // Primeira tentativa: com som
        videoElement.volume = 1.0;
        videoElement.muted = false;
        videoElement.currentTime = 0;
        await videoElement.play();
        setIsVideoReady(true);
        setIsMuted(false);
      } catch (error) {
        console.error('Tentando reproduzir com som:', error);
        
        // Segunda tentativa: com som após interação do usuário
        const unmute = async () => {
          try {
            videoElement.muted = false;
            videoElement.volume = 1.0;
            await videoElement.play();
            setIsMuted(false);
            document.removeEventListener('click', unmute);
          } catch (err) {
            console.error('Erro ao tentar ativar o som:', err);
          }
        };
        
        document.addEventListener('click', unmute);
  
        // Enquanto isso, tenta reproduzir mudo
        try {
          videoElement.muted = true;
          await videoElement.play();
          setIsVideoReady(true);
          setIsMuted(true);
        } catch (secondError) {
          console.error('Erro ao tentar reproduzir mudo:', secondError);
        }
      }
    };

    const handleTimeUpdate = () => {
      const progress = (videoElement.currentTime / videoElement.duration) * 100;
      setProgress(progress);
    };

    const handleEnded = () => {
      videoElement.currentTime = 0;
      videoElement.play().catch(() => {
        videoElement.muted = true;
        setIsMuted(true);
        videoElement.play();
      });
    };

    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    videoElement.addEventListener('ended', handleEnded);

   videoElement.addEventListener('loadeddata', initVideo);

    // Cleanup
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      videoElement.removeEventListener('ended', handleEnded);
      videoElement.removeEventListener('loadeddata', initVideo);
      if (videoElement) {
        videoElement.pause();
        videoElement.currentTime = 0;
      }
    };
  }, []);

  const handleNext = useCallback(() => {
    stopVideo();
    setIsClosing(true);
    onNext(); // Remove o setTimeout
  }, [onNext, stopVideo]);

  const handleSkip = useCallback(() => {
    stopVideo();
    setIsClosing(true);
    onSkip(); // Remove o setTimeout
  }, [onSkip, stopVideo]);

  useEffect(() => {
    return () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.pause();
        videoElement.src = '';
        videoElement.load();
      }
    };
  }, []);

  const handleCollapse = useCallback(() => {
    setIsCollapsed(prev => !prev);
  }, []);

  const handleCanPlay = useCallback(() => {
    setIsVideoReady(true);
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play().catch(() => {
        // Se falhar ao reproduzir com som, tenta com mute
        videoElement.muted = true;
        setIsMuted(true);
        videoElement.play().catch(console.error);
      });
    }
  }, []);

  const handleVideoError = useCallback((error) => {
    console.error('Erro ao carregar o vídeo:', {
      error,
      url: getVideoUrl()
    });
    setIsVideoReady(false);
  }, [getVideoUrl]);

  const toggleMute = useCallback(async () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const newMutedState = !isMuted;
      try {
        videoElement.muted = newMutedState;
        if (!newMutedState) {
          videoElement.volume = 1.0;
          await videoElement.play();
        }
        setIsMuted(newMutedState);
      } catch (error) {
        console.error('Erro ao alternar áudio:', error);
        videoElement.muted = true;
        setIsMuted(true);
      }
    }
  }, [isMuted]);


  return (
    <div className={`onboarding-step0 ${isClosing ? 'closing' : ''}`}>
      <div className="overlay" />
      <div className={`balloon ${isCollapsed ? 'collapsed' : ''}`}>
        {/* Header Section */}
        <div className="balloon-header">
          <div className="header-content">
            <div className="header-title">
              <span className="welcome-icon">👋</span>
              <h2>{isCollapsed ? 'Tutorial' : 'Olá, boas vindas ao CMOS.ai!'}</h2>
            </div>
          </div>
          <div className="balloon-controls">
            <button 
              type="button"
              onClick={handleCollapse} 
              className="control-button collapse-button"
              aria-label={isCollapsed ? 'Expandir' : 'Recolher'}
            >
              <FontAwesomeIcon 
                icon={isCollapsed ? faChevronRight : faChevronLeft} 
                className="control-icon"
              />
            </button>
            <button 
              type="button"
              onClick={handleSkip} 
              className="close-button"
              aria-label="Fechar"
            >
              <span className="close-icon">×</span>
            </button>
          </div>
        </div>
  
        {/* Content Section */}
        <div className="balloon-content">
          {/* Video Container */}
          <div className={`video-container ${!isVideoReady ? 'loading' : ''}`}>
            <video
              ref={videoRef}
              className="welcome-video"
              autoPlay
              loop
              playsInline
              preload="auto"
              muted={isMuted}
              onCanPlay={handleCanPlay}
              onError={handleVideoError}
            >
              <source src={getVideoUrl()} type="video/mp4" />
            </video>
  
            {/* Video Controls */}
            <div className="video-controls-wrapper">
              <div className="video-controls">
                <div className="video-progress-container">
                  <div className="video-progress-bar">
                    <div 
                      className="video-progress" 
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <span className="progress-indicator">{Math.round(progress)}%</span>
                </div>
                <button 
                  type="button" 
                  onClick={toggleMute}
                  className="volume-button"
                  aria-label={isMuted ? 'Ativar Som' : 'Desativar Som'}
                >
                  {isMuted ? (
                    <span className="volume-icon">🔇</span>
                  ) : (
                    <span className="volume-icon">🔊</span>
                  )}
                </button>
              </div>
            </div>
  
            {/* Loading State */}
            {!isVideoReady && (
              <div className="video-loading" aria-live="polite">
                <div className="loading-content">
                  <div className="loading-spinner" role="status" />
                  <span className="loading-text">Carregando vídeo...</span>
                </div>
              </div>
            )}
          </div>
  
          {/* Footer Section */}
          {!isCollapsed && (
            <div className="balloon-footer">
              <div className="footer-content">
                <p className="guide-text">
                  Vamos te guiar pela plataforma passo a passo
                  <span className="guide-emoji">✨</span>
                </p>
              </div>
              <div className="actions">
                <button 
                  type="button"
                  onClick={handleNext} 
                  className="next-button"
                >
                  Próximo
                  <FontAwesomeIcon 
                    icon={faChevronRight} 
                    className="next-icon"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Step0.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default Step0;