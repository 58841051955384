import axios from 'axios';
import { Auth } from 'aws-amplify';

const isProduction = process.env.NODE_ENV === 'production';
const baseURL = isProduction 
    ? 'https://api.cmos.ai'
    : process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    // Remove withCredentials se não estiver usando cookies de sessão
    withCredentials: false
});

// Interceptor para requisições
api.interceptors.request.use(
    async (config) => {
        try {
            // Adiciona token de autenticação
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // Apenas headers essenciais
            config.headers['Cache-Control'] = 'no-cache';

            // Garante prefixo /api correto
            if (!config.url.startsWith('/api/')) {
                config.url = `/api${config.url}`;
            }

            // Adiciona timestamp para evitar cache em GETs
            if (config.method?.toLowerCase() === 'get') {
                config.params = {
                    ...config.params,
                    _t: Date.now()
                };
            }

            // Log da requisição
            console.log('Requisição:', {
                url: `${config.baseURL}${config.url}`,
                method: config.method,
                headers: config.headers
            });

            return config;
        } catch (error) {
            console.error('Erro no interceptor:', error);
            return Promise.reject(error);
        }
    }
);

// Interceptor para respostas
api.interceptors.response.use(
    (response) => {
        // Verifica resposta HTML
        if (typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
            console.error('Resposta HTML detectada:', {
                url: `${response.config.baseURL}${response.config.url}`
            });
            return Promise.reject(new Error('Resposta HTML inválida'));
        }

        return response;
    },
    async (error) => {
        console.error('Erro na requisição:', {
            url: error.config?.url,
            status: error.response?.status,
            message: error.message
        });

        // Retry para erros 404 em produção
        if (isProduction && error.response?.status === 404 && !error.config?._retry) {
            error.config._retry = true;
            const newUrl = error.config.url.startsWith('/api/') 
                ? error.config.url.substring(4)
                : `/api${error.config.url}`;
            
            return api({
                ...error.config,
                url: newUrl
            });
        }

        return Promise.reject(error);
    }
);

export default api;