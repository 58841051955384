// src/app/generation-model-class/GenerationModelClassSelector.jsx

import React, {useEffect, useState} from 'react';
import {useGenerationModelClasses} from "../../utils/hooks/useGenerationModels";
import {Avatar, Button, Dropdown, Popover, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import OpenAIIcon from "../../images/icons/openai.svg";
import AnthropicIcon from "../../images/icons/anthropic.jpeg";
import {useGenerationModelClass} from "../../utils/hooks/useGenerationModelClass";
import UpgradeButton from "../../components/subscription/UpgradeButton";
import {useCurrentSubscriptionPlans} from "../../utils/hooks/useCurrentSubscriptionPlan";
import {Theme} from "../../api/Theme";
import GoogleIcon from "../../images/icons/google.png";

const GenerationModelClassSelector = () => {
    const [selectedGenerationModelClass, setSelectedGenerationModelClass] = useState(null);

    const { generationModelClasses, isLoading } = useGenerationModelClasses();
    const { generationModelClass, setGenerationModelClass } = useGenerationModelClass();
    const { currentSubscriptionPlans } = useCurrentSubscriptionPlans();

    const [currentGenerationModelClass, setCurrentGenerationModelClass] = useState(null);

    // Detecta se o usuário está no plano Freemiumm
    const isFreemium = currentSubscriptionPlans?.some(plan =>
        plan.planName?.toLowerCase() === "freemium"
    );

    const ORDERED_MODELS = [
        "gemini-flash",    // 1. Gemini Flash 1.5
        "gemini-pro",      // 2. Gemini Pro 1.5
        "gpt-4o-mini",     // 3. GPT-4o mini
        "gpt-4o",          // 4. GPT-4o
        "cmosai-regular",  // 5. CMOs.ai Regular
        "cmosai-advanced", // 6. CMOs.ai Avançado
        "claude-3-haiku",  // 7. Claude Haiku 3
        "claude-3.5-sonnet", // 8. Claude Sonnet 3.5
        "claude-3-opus"    // 9. Claude Opus 3
    ];

    useEffect(() => {
        // Se não houver modelo selecionado ainda
        if (!currentGenerationModelClass) {
            // Obtém os modelos disponíveis para o plano do usuário
            const availableModels = currentSubscriptionPlans
                ?.map(plan => plan.generationModelClasses)
                .flat() || [];
    
    
            // Define o modelo padrão baseado no plano
            let defaultModel;
            
            if (isFreemium) {
                // Para Freemium, tenta usar GPT-4o mini
                defaultModel = "gpt-4o-mini";
            } else {
                // Para outros planos, usa o primeiro modelo disponível na ordem preferida
                defaultModel = ORDERED_MODELS.find(model => availableModels.includes(model));
            }
    
    
            // Verifica se o modelo padrão está disponível
            if (defaultModel && availableModels.includes(defaultModel)) {
                setSelectedGenerationModelClass(defaultModel);
                setGenerationModelClass(defaultModel);
                setCurrentGenerationModelClass(defaultModel);
            } else {
                // Se o modelo padrão não estiver disponível, usa o primeiro modelo disponível
                const firstAvailable = availableModels[0];
                if (firstAvailable) {
                    setSelectedGenerationModelClass(firstAvailable);
                    setGenerationModelClass(firstAvailable);
                    setCurrentGenerationModelClass(firstAvailable);
                }
            }
        } else {
            // Se já existe um modelo selecionado, verifica se ainda está disponível
            const availableModels = currentSubscriptionPlans
                ?.map(plan => plan.generationModelClasses)
                .flat() || [];
    
            if (availableModels.includes(generationModelClass)) {
                setCurrentGenerationModelClass(generationModelClass);
                setSelectedGenerationModelClass(generationModelClass);
            } else {
                // Se o modelo atual não estiver mais disponível, seleciona o primeiro disponível
                const firstAvailable = availableModels[0];
                if (firstAvailable) {
                    setSelectedGenerationModelClass(firstAvailable);
                    setGenerationModelClass(firstAvailable);
                    setCurrentGenerationModelClass(firstAvailable);
                }
            }
        }
    }, [generationModelClass, isFreemium, currentSubscriptionPlans]);

    const handleGenerationModelClassChange = (model) => {
        if (isGenerationModelClassAvailable(model)) {
            setGenerationModelClass(model);
            setSelectedGenerationModelClass(model);
        }
    };

    const renderOptionLabel = (model) => {
        const component = (
            <div
                className="my-1 font-primary"
                onClick={() => handleGenerationModelClassChange(model.value)}
            >
                <div className="flex flex-row items-center gap-2">
                    <Avatar shape="square" src={getOptionIcon(model.value)} />

                    <div>
                        <div className="flex items-center">
                            <div className="text-reg max-w-[250px]">
                                {model.name}
                            </div>
                        </div>
                        <div className="text-xsm text-gray-400 max-w-[250px] whitespace-normal">
                            {model?.description}
                        </div>
                    </div>
                </div>
            </div>
        );

        // Se não estiver disponível no plano, exibe popover de "Upgrade"
        if (!isGenerationModelClassAvailable(model.value)) {
            return (
                <Popover
                    content={renderPopoverContent()}
                    trigger="hover"
                    placement="right"
                >
                    {component}
                </Popover>
            );
        } else {
            return component;
        }
    };

    const isGenerationModelClassAvailable = (model) => {
        const availableModels = currentSubscriptionPlans
            ?.map(plan => plan.generationModelClasses)
            .flat() || [];
        
        return availableModels.includes(model);
    };

    const getDropdownItems = () => {
        if (isLoading || !generationModelClasses) {
            return [];
        }
      
        // Copia o array original
        let items = [...generationModelClasses];
      
        // Aplica o sort de acordo com a posição em ORDERED_MODELS
        items.sort((a, b) => {
          const aIndex = ORDERED_MODELS.indexOf(a.value);
          const bIndex = ORDERED_MODELS.indexOf(b.value);
      
          // Se não encontrar no ORDERED_MODELS, deixa no final.
          if (aIndex === -1 && bIndex === -1) return 0;
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;
      
          return aIndex - bIndex;
        });
      
        // Gera os itens do dropdown já na ordem desejada
        return items.map(model => ({
          label: renderOptionLabel(model),
          key: model.value,
          disabled: !isGenerationModelClassAvailable(model.value)
        }));
    };

    const getOptionIcon = (model) => {
        if (!model) return null;

        if (model.startsWith("gpt")) {
            return OpenAIIcon;
        } else if (model.startsWith("claude")) {
            return AnthropicIcon;
        } else if (model.startsWith("gemini")) {
            return GoogleIcon;
        } else {
            return (
                <Avatar className="bg-premium" shape="square">
                    <FontAwesomeIcon icon={faWandMagicSparkles} />
                </Avatar>
            );
        }
    };

    const getSelectedGenerationModelClass = (model) => {
        if (isLoading || !generationModelClasses) {
            return null;
        }
        return generationModelClasses.find(item => item.value === model);
    };

    const renderPopoverContent = () => {
        return (
            <div className="font-primary w-[200px] flex flex-col gap-2">
                Para acessar modelos mais avançados, faça um upgrade de plano.
                <UpgradeButton size="large" />
            </div>
        );
    };

    return (
        <div className="generation-model-class-selector flex flex-row items-center gap-2">
            <Dropdown menu={{ items: getDropdownItems() }} trigger={['click']}>
                <Button
                    type="text"
                    size="large"
                    className="text-gray-800"
                >
                    <Avatar
                        shape="square"
                        src={getOptionIcon(
                            getSelectedGenerationModelClass(
                                currentGenerationModelClass
                            )?.value
                        )}
                    />
                    {getSelectedGenerationModelClass(currentGenerationModelClass)?.name}
                    <FontAwesomeIcon icon={faChevronDown} />
                </Button>
            </Dropdown>
            <Tag color={Theme.PRIMARY}>Novo</Tag>
        </div>
    );
};

export default GenerationModelClassSelector;