// src/components/onboarding/agents/Step1.jsx

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Step1.css';

const AgentsStep1 = ({ onNext, onSkip }) => {
  const [position, setPosition] = useState(null);
  const [arrowDirection, setArrowDirection] = useState('left');
  const [isVisible, setIsVisible] = useState(false); // Novo estado para controle de visibilidade

  useEffect(() => {
    const adjustPosition = () => {
      const categoryElements = document.querySelectorAll('.ToolsFilterCategoryButton');
      if (categoryElements && categoryElements.length > 0) {
        const lastCategoryElement = categoryElements[categoryElements.length - 1];
        const rect = lastCategoryElement.getBoundingClientRect();

        const balloon = document.querySelector('.agents-onboarding-step1 .balloon');
        const balloonWidth = balloon ? balloon.offsetWidth : 300;

        const viewportWidth = window.innerWidth;
        const spaceRight = viewportWidth - rect.right;

        let top, left;

        // Detecta telas menores
        const isSmallScreen = viewportWidth <= 600;

        if (isSmallScreen) {
          // Em telas menores, posiciona abaixo do último botão
          top = rect.bottom + window.scrollY + 10; // Posiciona abaixo do botão
          left = rect.left + window.scrollX;

          // Ajuste se o balão ultrapassar a largura da tela
          if (left + balloonWidth > viewportWidth - 20) {
            left = viewportWidth - balloonWidth - 20; // Deixa uma margem de 20px
          }
          if (left < 20) {
            left = 20; // Garante que não saia da tela pela esquerda
          }

          setArrowDirection('top-right'); // Seta no canto superior direito
        } else {
          if (spaceRight >= balloonWidth + 20) {
            top = rect.top + window.scrollY - 20; // Ajuste vertical
            left = rect.right + window.scrollX + 20; // Ajuste horizontal
            setArrowDirection('left'); // Seta aponta para a esquerda
          } else {
            // Espaço insuficiente, posiciona abaixo do último botão
            top = rect.bottom + window.scrollY + 10; // Posiciona abaixo do botão
            left = rect.left + window.scrollX;

            // Ajuste se o balão ultrapassar a largura da tela
            if (left + balloonWidth > viewportWidth - 20) {
              left = viewportWidth - balloonWidth - 20; // Deixa uma margem de 20px
            }
            if (left < 20) {
              left = 20; // Garante que não saia da tela pela esquerda
            }

            setArrowDirection('top'); // Seta aponta para cima
          }
        }

        setPosition({ top, left });
      } else {
        setTimeout(adjustPosition, 100);
      }
    };

    // Chama a função após um pequeno atraso
    setTimeout(adjustPosition, 0);

    // Atualiza a posição em caso de redimensionamento da janela
    window.addEventListener('resize', adjustPosition);

    // Adiciona a classe 'show' para iniciar a animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Pequeno atraso para garantir a transição

    // Limpeza
    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  // Função para lidar com o clique no botão "Próximo"
  const handleNext = () => {
    setIsVisible(false); // Inicia a animação de saída
    setTimeout(() => {
      onNext(); // Chama a função onNext após a animação
    }, 500); // Tempo deve corresponder à duração da transição (0.5s)
  };

    // Função para lidar com o clique no botão "X"
    const handleSkip = () => {
      setIsVisible(false);
      setTimeout(() => {
        onSkip();
      }, 500);
    };

  // Se a posição ainda não foi calculada, não renderiza o balão
  if (!position) {
    return null;
  }

  return (
    <div
      className="agents-onboarding-step1"
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        zIndex: 1000,
        maxWidth: 'calc(100% - 40px)', // Garante que o balão não ultrapasse a tela
      }}
    >
      <div className={`balloon balloon-arrow-${arrowDirection} ${isVisible ? 'show' : ''}`}>
        <button onClick={handleSkip} className="close-button">×</button>
        <span className="step-number">1</span>
        <p>
          Os agentes inteligentes estão especializados por temas.<br />
          Se preferir, digite o que deseja fazer no campo de busca para localizar um agente.
        </p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Próximo</button>
        </div>
      </div>
    </div>
  );
};

AgentsStep1.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired, // Adicione a validação da prop
};

export default AgentsStep1;
